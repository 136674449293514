import requirePolyfills from './utils/load-dynamic-polyfills';
import compile from './compile';
import fetchPlaygroundInfo from './utils/realtime-utils';
import { getModules } from './utils/modules';
import registerServiceWorker from './registerServiceWorker';
import { createOverlay } from './overlay/overlay-manager';

export const SCRIPT_VERSION =
  document.currentScript && document.currentScript.src;

requirePolyfills().then(() => {
  registerServiceWorker('/preview-service-worker.js', {});

  createOverlay();

  require('core-js/fn/string/starts-with');
  require('core-js/fn/string/ends-with');
  require('core-js/fn/array/find');
  require('core-js/fn/promise');

  function sendReady() {
    // dispatch({ type: 'initialized' });
  }

  // async function handleMessage(data, source) {
  async function handleMessage({ data, source, origin }) {
    if (source) {
      switch (data.type) {
        case 'compile':
          const id = document.location.pathname.replace('/', '');
          const compileData = {
            modules: getModules(
              data.files,
              data.dependencies,
              data.activeFilePath
            ),
            entry: '/src/index.js',
            sandboxId: id
          };

          compile(compileData);
          return;
        case 'refresh':
          document.location.reload();
          return;
        default:
          return;
      }
    }
  }

  document.addEventListener('DOMContentLoaded', async () => {
    const firestore = self.firebase.firestore();
    const database = self.firebase.database();
    const auth = self.firebase.auth();

    await auth.signInAnonymously();

    const playgroundId = document.location.pathname.replace('/', '');

    const playgroundInfo = await fetchPlaygroundInfo(
      firestore.doc(`playgrounds/${playgroundId}`),
      database.ref(`playgrounds/${playgroundId}/files`)
    );

    const data = {
      modules: getModules(playgroundInfo.files, playgroundInfo.dependencies),
      entry: '/src/index.js',
      hasActions: false,
      sandboxId: playgroundId
    };
    compile(data);
  });

  self.addEventListener('message', handleMessage);
});
