import firebase from 'firebase';
import TextOperation from './TextOperation';

const SENTINEL_CONSTANTS: Record<string, any> = {
  // A special character we insert at the beginning of lines so we can attach attributes to it to represent
  // "line attributes."  E000 is from the unicode "private use" range.
  LINE_SENTINEL_CHARACTER: '\uE000',

  // A special character used to represent any "entity" inserted into the document (e.g. an image).
  ENTITY_SENTINEL_CHARACTER: '\uE001'
};

var characters =
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
const revisionToId = (revision: number) => {
  if (revision === 0) {
    return 'A0';
  }

  var str = '';
  while (revision > 0) {
    var digit = revision % characters.length;
    str = characters[digit] + str;
    revision -= digit;
    revision /= characters.length;
  }

  // Prefix with length (starting at 'A' for length 1) to ensure the id's sort lexicographically.
  var prefix = characters[str.length + 9];
  return prefix + str;
};

const revisionFromId = (revisionId: string) => {
  if (
    !(
      revisionId.length > 0 &&
      revisionId[0] === characters[revisionId.length + 8]
    )
  ) {
    return;
  }
  var revision = 0;
  for (var i = 1; i < revisionId.length; i++) {
    revision *= characters.length;
    revision += characters.indexOf(revisionId[i]);
  }
  return revision;
};

const parseRevision = (data: any, fileDocument: TextOperation) => {
  // We could do some of this validation via security rules.  But it's nice to be robust, just in case.
  if (typeof data !== 'object') {
    return null;
  }
  if (typeof data.a !== 'string' || typeof data.o !== 'object') {
    return null;
  }
  var op = null;
  try {
    op = TextOperation.fromJSON(data.o);
  } catch (e) {
    return null;
  }

  if (op?.baseLength !== fileDocument.targetLength) {
    return null;
  }
  return { author: data.a, operation: op };
};

const fetchHistory = (
  r: firebase.database.Reference,
  s: firebase.database.DataSnapshot
) => {
  // var self = this;
  // Get the latest checkpoint as a starting point so we don't have to re-play entire history.
  // this.ref_.child('checkpoint').once('value', function(s) {
  // if (self.zombie_) { return; } // just in case we were cleaned up before we got the checkpoint data.

  let checkpointRevisionData: { o: any; a: string } | undefined;
  let startAtRevision: number;

  var revisionId: string = s.child('id').val(),
    op = s.child('o').val(),
    author: string = s.child('a').val();
  if (op != null && revisionId != null && author !== null) {
    checkpointRevisionData = { o: op, a: author };
    startAtRevision = revisionFromId(revisionId) || 0;
    // startAtRevisionId = revisionId;
    // monitorHistoryStartingAt_(self.checkpointRevision_ + 1);
  } else {
    startAtRevision = 0;
    // monitorHistoryStartingAt_(self.checkpointRevision_);
  }

  return handleInitialRevisions_({
    ref: r,
    initialRevision: startAtRevision,
    checkpointRevisionData
  });
};

const handleInitialRevisions_ = async ({
  checkpointRevisionData,
  initialRevision,
  ref
}: {
  checkpointRevisionData?: { o: any; a: string };
  initialRevision: number;
  ref: firebase.database.Reference;
}): Promise<string | undefined> => {
  const initialRevisionId = revisionToId(initialRevision);

  var historyRef = ref.child('history').startAt(null, initialRevisionId);
  let fileDocument = new TextOperation();

  // assert(!this.ready_, 'Should not be called multiple times.');

  // Compose the checkpoint and all subsequent revisions into a single operation to apply at once.
  // revision_ = this.checkpointRevision_;
  try {
    const snap = await historyRef.once('value');

    const pendingReceivedRevisions = snap.val();

    if (initialRevisionId && checkpointRevisionData) {
      pendingReceivedRevisions[initialRevisionId] = checkpointRevisionData;
    }

    //  = {
    //   initialRevision: checkpointRevisionData,
    //   ...snap.val()
    // };
    // self.handleInitialRevisions_();

    var currentRevision = initialRevision;
    var revisionId = revisionToId(initialRevision);
    // var revisionId = revisionToId(this.revision_),
    // pending = this.pendingReceivedRevisions_;

    while (pendingReceivedRevisions[revisionId] != null) {
      var revision = parseRevision(
        pendingReceivedRevisions[revisionId],
        fileDocument
      );
      if (!revision) {
        // If a misbehaved client adds a bad operation, just ignore it.
        // utils.log(
        //   'Invalid operation.',
        //   this.ref_.toString(),
        //   revisionId,
        //   pending[revisionId]
        // );
      } else {
        fileDocument = fileDocument.compose(revision.operation);
      }

      delete pendingReceivedRevisions[revisionId];
      // this.revision_++;
      currentRevision++;
      revisionId = revisionToId(currentRevision);
    }

    let code = fileDocument.apply('') || '';
    for (let key in SENTINEL_CONSTANTS) {
      code = code.replace(new RegExp(SENTINEL_CONSTANTS[key], 'g'), '');
    }

    return code;
  } catch (e) {
    // console.log(e);
  }

  // });

  // this.trigger('operation', this.document_);

  // this.ready_ = true;
  // var self = this;
  // setTimeout(function() {
  //   self.trigger('ready');
  // }, 0);
};

const fetchPlaygroundFileData = async (ref: firebase.database.Reference) => {
  const checkpointSnapshot = await ref.child('checkpoint').once('value');
  return fetchHistory(ref, checkpointSnapshot);
};

const fetchPlaygroundInfo = async (
  firestorePlaygroundRef: firebase.firestore.DocumentReference,
  databaseFilesRef: firebase.database.Reference
) => {
  const playgroundInfoData = await firestorePlaygroundRef.get();
  const playgroundFiles = await firestorePlaygroundRef
    .collection('files')
    .get();

  const playgroundInfo = playgroundInfoData.data();

  const dependencies = playgroundInfo?.dependencies;
  const files = await Promise.all(
    playgroundFiles.docs.map(async file => {
      const { name, realtimeDatabaseId } = file.data();
      const fileContents = await fetchPlaygroundFileData(
        databaseFilesRef.child(realtimeDatabaseId)
      );

      return { path: '/src/' + name, code: fileContents };
    })
  );

  return { files, dependencies };
};

export default fetchPlaygroundInfo;
