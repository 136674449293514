const OVERLAY_ELEMENT_ID = 'preview-overlay';
const OVERLAY_STATUS_TEXT_ID = 'preview-overlay-text';

let _overlayElement: HTMLDivElement | undefined;
let _overlaySpinner: HTMLDivElement | undefined;

export const createOverlay = () => {
  const overlayElement =
    _overlayElement || document.getElementById(OVERLAY_ELEMENT_ID);

  if (overlayElement) {
    return;
  }

  _overlayElement = document.createElement('div');
  _overlaySpinner = document.createElement('div');

  _overlayElement.setAttribute('id', OVERLAY_ELEMENT_ID);
  _overlayElement.setAttribute(
    'style',
    'height: 100vh; width: 100%; margin-left: -10px; margin-right: -10px;display: flex; flex: 1;align-items: center; justify-content: center;z-index: 100;background-color: black;transition: opacity 0.15s ease-in;'
  );

  _overlayElement.appendChild(_overlaySpinner);
  document.body.appendChild(_overlayElement);
};

export const setOverlayStatus = (status: string) => {
  if (_overlaySpinner) {
    _overlaySpinner.innerHTML = `<style type="text/css">
    .spacer {
      display: flex;
      flex: 1;
    }
    
    .loader {
      border: 3px solid #000000;
      border-top: 3px solid #ffffff;
      border-right: 3px solid #ffffff;
      border-bottom: 3px solid #ffffff;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      animation: spin 0.5s linear infinite;
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  </style>
  <div class="loader"></div>
  `;
  }
};
