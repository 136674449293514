import _debug from '../utils/debug';
import { getAbsoluteDependencies } from '../utils/dependencies';

import dependenciesToQuery from './dependencies-to-query';

type Dependencies = {
  [dependency: string]: string;
};

const RETRY_COUNT = 1;
const debug = _debug('sandbox:packager');

const VERSION = 1;

const PACKAGER_URL =
  process.env.BUILD_ENV === 'production'
    ? 'https://us-central1-variable-production.cloudfunctions.net/onPackageDependencies'
    : 'https://us-central1-react-editor-32b3d.cloudfunctions.net/onPackageDependencies';

function callApi(
  url: string,
  headers: Record<string, any> = {},
  method = 'GET'
) {
  return fetch(url, {
    headers,
    method
  })
    .then(async response => {
      if (!response.url) {
        const error = new Error(response.statusText || '' + response.status);

        const message = await response.json();

        // @ts-ignore
        error.response = message;
        // @ts-ignore
        error.statusCode = response.status;
        throw error;
      }

      return response;
    })
    .then(response => response.json());
}

/**
 * Request the packager, if retries > RETRY_COUNT it will throw if something goes wrong
 * otherwise it will retry again with an incremented retry
 *
 * @param {string} query The dependencies to call
 */
async function requestPackager(url: string, method = 'GET') {
  // let retries = 0;

  // eslint-disable-next-line no-constant-condition
  // while (true) {

  // debug(`Trying to call packager for ${retries} time`);
  try {
    const manifest = await callApi(url, {}, method); // eslint-disable-line no-await-in-loop

    return manifest;
  } catch (e) {
    if (e.response && e.statusCode !== 504) {
      throw new Error(e.response.error);
    }
    // 403 status code means the bundler is still bundling
    // if (retries < RETRY_COUNT) {
    //   retries += 1;
    //   await delay(1000 * 2); // eslint-disable-line no-await-in-loop
    // } else {
    //   throw e;
    // }
  }
  // }
}

function dependenciesToBucketPath(dependencies: Record<string, string>) {
  return `v${VERSION}/combinations/${Object.keys(dependencies)
    .sort()
    .map(
      // Paths starting with slashes don't work with cloudfront, even escaped. So we remove the slashes
      dep => `${dep.replace('/', '-').replace('@', '')}@${dependencies[dep]}`
      // `${encodeURIComponent(dep.replace('/', '-').replace('@', ''))}@${
      //   dependencies[dep]
      // }`
    )
    .join('+')}.json`;
  // .join('%2B')}.json?alt=media`;
}

/**
 * Some dependencies have a space in their version for some reason, this is invalid and we
 * ignore them. This is what yarn does as well.
 */
function removeSpacesFromDependencies(dependencies: Record<string, string>) {
  const newDeps: Record<string, string> = {};
  Object.keys(dependencies).forEach(depName => {
    const [version] = dependencies[depName].split(' ');
    newDeps[depName] = version;
  });
  return newDeps;
}

async function getDependencies(
  dependencies: Record<string, string>,
  showLoadingFullScreen: boolean
) {
  const absoluteDependencies = await getAbsoluteDependencies(
    removeSpacesFromDependencies(dependencies)
  );
  const dependencyUrl = dependenciesToQuery(absoluteDependencies);
  const bucketDependencyUrl = dependenciesToBucketPath(absoluteDependencies);

  // setScreen({
  //   type: 'loading',
  //   text: 'Downloading Dependencies...',
  //   showFullScreen: showLoadingFullScreen
  // });
  const storage = self.firebase.storage();
  // const database = self.firebase.database();
  // return packages;

  try {
    const bucketManifestUrl = await storage
      .ref(bucketDependencyUrl)
      .getDownloadURL();

    const bucketManifest = await callApi(bucketManifestUrl);
    return bucketManifest;
  } catch (e) {
    //   // setScreen({
    //   //   type: 'loading',
    //   //   text: 'Resolving Dependencies...',
    //   //   showFullScreen: showLoadingFullScreen
    //   // });

    //   // The dep has not been generated yet...
    await requestPackager(`${PACKAGER_URL}/${dependencyUrl}`, 'POST');

    const bucketManifestUrl = await storage
      .ref(bucketDependencyUrl)
      .getDownloadURL();
    const bucketManifest = await callApi(bucketManifestUrl);

    return bucketManifest;
    //   // setScreen({
    //   //   type: 'loading',
    //   //   text: 'Downloading Dependencies...',
    //   //   showFullScreen: showLoadingFullScreen
    //   // });
    //   return;
    // return requestPackager(`${BUCKET_URL}/${url}`);
  }
}

export async function fetchDependencies(
  npmDependencies: Dependencies,
  _: any,
  showLoaderFullScreen: boolean
) {
  if (Object.keys(npmDependencies).length !== 0) {
    // New Packager flow

    try {
      const result = await getDependencies(
        npmDependencies,
        showLoaderFullScreen
      );

      if (showLoaderFullScreen) {
        // setScreen({
        //   type: 'loading',
        //   text: 'Transpiling Modules...',
        //   showFullScreen: showLoaderFullScreen
        // });
      }

      return result;
    } catch (e) {
      console.log(
        'Could not fetch dependencies, please try again in a few moments'
      );
      // e.message = `Could not fetch dependencies, please try again in a couple seconds: ${e.message}`;
      // dispatch(actions.notifications.show(e.message, 'error'));
      // throw e;
    }
  }
  return false;
}
