function TextOp(type) {
  this.type = type;
  this.chars = null;
  this.text = null;
  this.attributes = null;

  if (type === 'insert') {
    this.text = arguments[1];
    if (typeof this.text !== 'string') return;
    // utils.assert(typeof this.text === 'string');
    this.attributes = arguments[2] || {};
    // utils.assert(typeof this.attributes === 'object');
    if (typeof this.attributes !== 'object') return;
  } else if (type === 'delete') {
    this.chars = arguments[1];
    if (typeof this.chars !== 'number') return;
    // utils.assert(typeof this.chars === 'number');
  } else if (type === 'retain') {
    this.chars = arguments[1];
    if (typeof this.chars !== 'number') return;
    this.attributes = arguments[2] || {};
    // utils.assert(typeof this.attributes === 'object');
    if (typeof this.attributes !== 'object') return;
  }
}

TextOp.prototype.isInsert = function() {
  return this.type === 'insert';
};
TextOp.prototype.isDelete = function() {
  return this.type === 'delete';
};
TextOp.prototype.isRetain = function() {
  return this.type === 'retain';
};

TextOp.prototype.equals = function(other) {
  return (
    this.type === other.type &&
    this.text === other.text &&
    this.chars === other.chars &&
    this.attributesEqual(other.attributes)
  );
};

TextOp.prototype.attributesEqual = function(otherAttributes) {
  for (var attr in this.attributes) {
    if (this.attributes[attr] !== otherAttributes[attr]) {
      return false;
    }
  }

  for (attr in otherAttributes) {
    if (this.attributes[attr] !== otherAttributes[attr]) {
      return false;
    }
  }

  return true;
};

TextOp.prototype.hasEmptyAttributes = function() {
  var empty = true;
  for (var attr in this.attributes) {
    empty = false;
    break;
  }

  return empty;
};

export default TextOp;
